import React, { FC, useRef, useState, useLayoutEffect, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import Template from './Template';
import { useInvitation } from '@/hooks/useInvitation';
import { toast } from 'react-hot-toast';
import { FcHighPriority } from 'react-icons/fc';
import { saveSessionStorage, loadSessionStorage } from '../../utils/utils';
import CircleClose from '../icon/CircleClose';
import SendBigButton from '../icon/SendBigButton';
import CopyIcon from '../icon/CopyIcon';
import ArrowDown from '../icon/ArrowDown';
import EmailViewDropdown from '../header/EmailViewDropdown';
import CopyLinkShareDropdown from '../header/CopyLinkShareDropdown';
import ShareInputViewDropdown from '../header/ShareInputViewDropdown';
import { pathState } from '@/state/appState';
import { useRecoilValue } from 'recoil';
import { useCaptureInfo } from '@/hooks/useCaptureInfo';
import {
  captureListAtHostState,
  captureListSharedAtHostState,
  currentCaptureCreatorUpdatedInfoState,
  currentCaptureInfoState,
  currentCaptureShareInvitListState,
  getCurrentUserAccessType,
} from '@/state/captureState';
import { currentUserInfoState, isAnonymousState } from '@/state/userState';
import { User } from 'firebase/auth';
import UserAvatar from '../UserAvatar';
import dayjs from 'dayjs';
import ArrowDownShare from '../icon/ArrowDownShare';
import ArrowUpShare from '../icon/ArrowUpShare';
import _ from 'lodash';
import ShareModalList from './ShareModalList';
import { updateProjectCapture } from '@/lib/firebase/firestore';
import { showMyProjectState } from '@/state/uiState';
import { getUser, getUserWithEmail } from '@/lib/firebase/firestore/user';
import { useAuth } from '@/hooks/useAuth';
import { useGetUserSubscription } from '@/hooks/useGetUserSubscription';
import { NotifyMessage, useShowNotifyMsg } from '../notify';
import { useClerk, useOrganization } from '@clerk/clerk-react';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  url: string;
};

const emailSchema = /\b[\w\.-]+@[\w{2,*}\.-]+\.\w{2,4}\b/;
const ShareModal: FC<Props> = ({ isOpen, closeModal, url }) => {
  const myref = useRef<HTMLDivElement | null>(null);

  let unsub: Function | null = null;
  const [isCopyLinkShareDropdownShow, setIsCopyLinkShareDropdownShow] =
    useState(false);
  const [isShareInputViewDropdownShow, setIsShareInputViewDropdownShow] =
    useState(false);
  const [isCarrotUp, setIsCarrotUp] = useState(false);
  const [publicAccess, setTogglePublic] = useState(true);
  const [toggleAnonymousCommenting, setToggleAnonymousComment] = useState(true);
  const toggleRadioClass = ' transform translate-14px  custom-radio';
  const { uid, cid } = useRecoilValue(pathState);
  const currentCaptureInfo = useRecoilValue(currentCaptureInfoState);
  const { setInvitation, setCurrentCaptureShareInviteList } = useInvitation();
  const linkRef = useRef<null | HTMLInputElement>(null);
  const inviteRef = useRef<null | HTMLInputElement>(null);
  const messageRef = useRef<null | HTMLTextAreaElement>(null);
  const copyLinkRef = useRef<null | HTMLDivElement>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [emailsSent, setEmailsSent] = useState<any[]>([]);
  const [checkInvite, setCheckInvite] = useState<boolean>(false);
  const isShowingMyProject = useRecoilValue(showMyProjectState);
  // const [sessionStorage] = useState<any[]>(loadSessionStorage('INVITE-EMAILS'));
  const currentUserInfo = useRecoilValue(currentUserInfoState) as any;
  let captureListAtHost = useRecoilValue(
    isShowingMyProject ? captureListAtHostState : captureListSharedAtHostState
  );
  const { updatePorjectPublicAccess, updateCapturePublicAccess } = useCaptureInfo();
  const [roleSelectedDropDown, setroleSelectedDropDown] = useState<{
    role: Capture.RoleAssigned;
    isEntireProject: boolean;
  }>({ role: 'VIEW', isEntireProject: false });
  const currentCaptureShareInviteList = useRecoilValue(
    currentCaptureShareInvitListState
  );
  const [sharedInviteEmails, setShareInviteEmails] = useState(
    new Set<string>()
  );
  const currentCommentCreator = useRecoilValue(
    currentCaptureCreatorUpdatedInfoState
  );
  const showMyProjects = useRecoilValue(
    showMyProjectState
  );
  const currentAccessType = useRecoilValue(getCurrentUserAccessType);
  const [typing, setIsTyping] = useState(false);
  const { organization } = useOrganization();

  const cursornotAllowed = (currentAccessType !== 'CAPTURE-OWNER' && !organization) ? ' cursor-not-allowed ' : ' cursor-pointer ';
  // const cursornotAllowed = (currentAccessType !== 'CAPTURE-OWNER') ? ' cursor-not-allowed ' : ' cursor-pointer ';
  const handleClickShareInputViewDropdown = (event: any) => {
    if (currentAccessType === 'VIEW-CAPUTRE-ACCESS' && !organization) return false;
    // if (currentAccessType === 'VIEW-CAPUTRE-ACCESS') return false;
    setIsShareInputViewDropdownShow(
      (isShareInputViewDropdownShow) => !isShareInputViewDropdownShow
    );
    setIsCarrotUp((isCarrotUp) => !isCarrotUp);
  };
  const [displayOwnerName, setDisplayOwnerName] = useState('');

  const { subscription } = useGetUserSubscription(currentUserInfo.uid);
  const { onButtonClick, showDiv } = useShowNotifyMsg();

  const handleClickCopyLinkShareDropdown = () => {
    setIsCopyLinkShareDropdownShow(
      (isCopyLinkShareDropdownShow) => !isCopyLinkShareDropdownShow
    );
  };
  useLayoutEffect(() => {
    if (linkRef.current !== null) {
      linkRef.current.value = url;
    }
  });
  useEffect(() => {
    if (organization) {
      setDisplayOwnerName(organization.name)
      return
    }

    if (String(currentCaptureInfo?.creator.uid).length > 0) {
      getUser(currentCaptureInfo?.creator.uid || '').then((user: any) => {
        if (!user)
          setDisplayOwnerName(
            currentCaptureInfo?.creator.displayName ||
            currentCaptureInfo?.creator.email ||
            ''
          );
        setDisplayOwnerName(user?.displayName || currentCaptureInfo?.creator.displayName);
      });
    }
  }, [isOpen]);
  // useEffect(() => {
  //   if (emailsSent) {
  //     // setCurrentCaptureShareInviteList(cid);
  //   }
  // }, [checkInvite, roleSelectedDropDown]);

  useEffect(() => {
    const localArrEmails = loadSessionStorage('INVITE-EMAILS');
    let shareInvitesEmails = _.map(currentCaptureShareInviteList, 'email');

    setShareInviteEmails(new Set(shareInvitesEmails));
    if (localArrEmails?.length) {
      setEmailsSent(localArrEmails);
    }
  }, []);
  useEffect(() => {
    let shareInvitesEmails = _.map(currentCaptureShareInviteList, 'email');
    setShareInviteEmails(new Set(shareInvitesEmails));
  }, [checkInvite]);
  useEffect(() => {
    const localArrEmails = loadSessionStorage('INVITE-EMAILS');
    let shareInvitesEmails = _.map(currentCaptureShareInviteList, 'email');
    setShareInviteEmails(new Set(shareInvitesEmails));
    if (localArrEmails?.length) {
      setEmailsSent(localArrEmails);
    }
  }, [currentCaptureShareInviteList]);
  const fetchName = async (object: Capture.ShareModel) => {
    let users = await getUserWithEmail(object.email);
    if (users && users.length > 0) {
      // console.log(`User Found ${JSON.stringify(users)}`)
      return users[users.length - 1].displayName || '';
    }
  };

  const shareLink = async () => {
    if (linkRef.current !== null) {
      try {
        await navigator.clipboard.writeText(url);
        toast.success('Capture URL copied to Clipboard!');
      } catch (err) {
        toast.error('Coping the capture URL to Clipboard error.');
      }
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', clickOutside);

    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShareInputViewDropdownShow]);
  useEffect(() => {
    document.addEventListener('mousedown', clickOutsideCopy);

    return () => {
      document.removeEventListener('mousedown', clickOutsideCopy);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCopyLinkShareDropdownShow]);
  const clickOutside = (e: MouseEvent) => {
    if (myref.current && myref?.current.contains(e.target as Node))
      return false;
    // outside click && already opened
    if (isShareInputViewDropdownShow)
      return handleClickShareInputViewDropdown(e);
  };
  const clickOutsideCopy = (e: MouseEvent) => {
    // inside clicku\

    if (copyLinkRef.current && copyLinkRef?.current.contains(e.target as Node))
      return false;
    // outside click && already opened
    if (isCopyLinkShareDropdownShow) return handleClickCopyLinkShareDropdown();
  };
  const emailValidation = (emails: string[]): boolean => {
    let isValid = true;
    emails.forEach((item) => {
      if (!emailSchema.test(item)) {
        isValid = false;
      }
    });

    return isValid;
  };

  const sendInvite = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (inviteRef.current !== null) {
      try {
        const emailStr = inviteRef.current.value;
        if (!emailStr.trim()) {
          inviteRef.current.focus();
          return false;
        }

        const emails = emailStr
          .split(',')
          .map((item) => item.trim())
          .filter((item) => item.length > 0);

        // if (emailValidation(emails)) {
        //   const result = await setInvitation(emails);
        //   if (result?.message) {
        //     toast.success(result.message, {
        //       style: {
        //         wordBreak: 'break-all',
        //         padding: '3rem 0.75rem 3rem 0.75rem',
        //       },
        //     });

        //     setEmailsSent((prevState) => {
        //       const arrayEmails = [...prevState, ...emails];

        //       const uniqueArray = arrayEmails.filter(
        //         (email, index, array) => array.indexOf(email) === index
        //       );

        //       saveSessionStorage('INVITE-EMAILS', uniqueArray);
        //       return uniqueArray;
        //     });
        //     setCheckInvite(true);
        //   }
        // } else {
        //   toast.error('Incorrect email data!', { icon: <FcHighPriority /> });
        // }
      } catch (err) {
        console.error('Sending invitation emails error:', err);
      }
    }
  };
  const sendInviteWithRoles = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (isProcessing) return;

    // if (currentAccessType !== 'VIEW-CAPUTRE-ACCESS' || (organization && !showMyProjects)) return
    if ((currentAccessType !== 'CAPTURE-OWNER' && currentAccessType !== "VIEW-CAPUTRE-ACCESS") || (organization && !showMyProjects)) return
    // if (currentAccessType !== 'VIEW-CAPUTRE-ACCESS' || (!showMyProjects)) return
    if (inviteRef.current !== null) {
      try {
        let validate = await handleKeyUp(event);
        if (!validate) {
          return false;
        }
        setCheckInvite(true);
        setIsProcessing(true);
        const emailStr = inviteRef.current.value;
        if (!emailStr.trim()) {
          inviteRef.current.focus();
          setCheckInvite(false);
          setIsProcessing(false);
          return false;
        }
        let shareModels: Capture.ShareModel[] = [];

        const emailArray = emailStr
          .split('|')
          .map((item) => item.trim())
          .filter((item) => item.length > 0);
        const emails = _.uniq(emailArray);
        let message = '';
        if (messageRef.current) {
          message = messageRef.current.value;
        }
        for (let email in emails) {
          const userId = organization ? organization.id : currentUserInfo.uid
          // const userId = currentUserInfo.uid
          if (
            roleSelectedDropDown.isEntireProject &&
            roleSelectedDropDown.isEntireProject === true
          ) {
            for await (let capture of captureListAtHost) {

              let shareModel: Capture.ShareModel = {
                email: emails[email],
                IniviteStatus: 'SENT',
                Role: roleSelectedDropDown.role,
                cid: capture.cid,
                creator: {
                  uid: userId,
                  displayName: currentUserInfo.displayName || '',
                  email: currentUserInfo.email || '',
                },
                groupName: currentCaptureInfo?.group || '',
                isGroupShare: roleSelectedDropDown.isEntireProject,
                updatedAt: dayjs().unix(),
                createdAt: dayjs().unix(),
                message: message,
                displayName: '',
                uid: '',
                updatedBy: userId,
              };
              shareModels?.push(shareModel);
            }
          } else {
            let shareModel: Capture.ShareModel = {
              email: emails[email],
              IniviteStatus: 'SENT',
              Role: roleSelectedDropDown.role,
              cid: cid,
              creator: {
                uid: userId,
                displayName: currentUserInfo.displayName || '',
                email: currentUserInfo.email || '',
              },
              groupName: currentCaptureInfo?.group || '',
              isGroupShare: roleSelectedDropDown.isEntireProject,
              updatedAt: dayjs().unix(),
              createdAt: dayjs().unix(),
              message: message,
              displayName: '',
              uid: '',
              updatedBy: userId,
            };
            shareModels?.push(shareModel);
          }
        }
        if (emailValidation(emails)) {
          // console.log(`Share Model to Sending Invitation ${JSON.stringify(shareModels)}`)
          const result = await setInvitation(shareModels);
          if (result?.message) {
            toast.success(result.message);

            setEmailsSent((prevState) => {
              const arrayEmails = [...prevState, ...emails];

              const uniqueArray = arrayEmails.filter(
                (email, index, array) => array.indexOf(email) === index
              );

              saveSessionStorage('INVITE-EMAILS', uniqueArray);
              return uniqueArray;
            });
            inviteRef.current.value = '';
            setCheckInvite(false);
            setIsProcessing(false);
            setIsTyping(false);
            setCurrentCaptureShareInviteList(cid);
          }
        } else {
          toast.error('Please Type Correct Email');
          setCheckInvite(false);
          setIsProcessing(false);
        }
      } catch (err) {
        setIsProcessing(false);
        console.error('Sending invitation emails error:', err);
      }
    }
  };
  const extractEmails = (emails: string) => {
    return emails.match(/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/gi);
  };
  const handleKeyUp = async (event: any) => {
    if (inviteRef.current !== null) {
      try {
        let status = true;
        let emailStr = inviteRef.current.value.toLowerCase();
        if (!emailStr.trim()) {
          inviteRef.current.focus();
          if (emailStr.trim() === '') {
            // console.log(`Trim ISSUE----------------------`)

            toast.error(`Please Type Correct Email.`);
          }
          setIsProcessing(false);
          return false;
        }
        let splittedStr = emailStr.trim().split('|');

        splittedStr = splittedStr.filter((e) => e.trim());
        let uniquesStrings = new Set(splittedStr);

        let deleted = uniquesStrings.delete(currentUserInfo.email || '');
        if (deleted) {
          // you cannot sent invite to yourself, you already have the access

          toast.error(
            currentUserInfo.email === currentCaptureInfo?.creator.email
              ? `Owner cant be invitee.`
              : `You cannot sent invite to yourself, You already have the access!`
          );
          status = false;
          return status;
        }
        if (extractEmails(emailStr)?.length != uniquesStrings.size) {
          toast.error(`Please Type Correct Email`);
          status = false;
          return status;
        }
        Array.from(sharedInviteEmails).every((element) => {
          if (uniquesStrings.has(element)) {
            uniquesStrings.delete(element);
            toast.error(`Invite for email already sent (${element}) !`);
            status = false;
            return status;
          }
        });

        inviteRef.current.value =
          Array.from(uniquesStrings).join('|').trim() +
          (uniquesStrings.size != 0 ? ' |' : '');
        return status;
      } catch (err) {
        console.error('Sending invitation emails error:', err);
        return false;
      }
    }
  };
  const togglePublicAccess = async (event: any) => {
    try {
      if (subscription === 'free' && currentUserInfo?.tier !== "1" && !organization) {
        onButtonClick();
        return false;
      }
      if (currentAccessType !== 'CAPTURE-OWNER' && !organization) return false;
      // if (currentAccessType !== 'CAPTURE-OWNER') return false;
      if (isProcessing) return;
      setIsProcessing(true);
      const userId = organization ? organization.id : uid
      // const userId = uid

      let data = await updateCapturePublicAccess(
        userId,
        currentCaptureInfo?.group || '',
        {
          publicAccess: !currentCaptureInfo?.publicAccess,
          allowAnonymousCommenting: true,
        }
      );
      toast.success(`Public Access Successfully updated.`);
      setIsProcessing(false);
    } catch (error) {
      toast.error(`Unable to update Public Access.`);
    }
  };
  const toggleAnonmousCommenting = async (event: any) => {
    try {
      if (currentAccessType !== 'CAPTURE-OWNER' && !organization) return false;
      // if (currentAccessType !== 'CAPTURE-OWNER') return false;
      if (isProcessing) return;
      setIsProcessing(true);
      const userId = organization ? organization.id : uid
      // const userId = uid
      let data = updateCapturePublicAccess(
        userId,
        currentCaptureInfo?.group || '',
        {
          allowAnonymousCommenting:
            !currentCaptureInfo?.allowAnonymousCommenting,
        }
      );
      toast.success(`Allow Anonymous Commenting Successfully updated.`);
      setIsProcessing(false);
    } catch (error) {
      toast.error(`Unable to Allow Anonymous Commenting.`);
    }
  };
  useEffect(() => {
    setTogglePublic(!currentCaptureInfo?.publicAccess);
    setToggleAnonymousComment(!currentCaptureInfo?.allowAnonymousCommenting);
  }, [currentCaptureInfo]);
  // console.log(url);

  return (
    <Template isOpen={isOpen} closeModal={closeModal}>
      <div
        className={`${isProcessing ? 'cursor-wait' : ''
          } share-modal inline-block w-full max-w-4xl overflow-hidden text-left align-middle transition-all transform rounded-xl bg-white sm:h-screen sm:rounded-none sm:border-0`}
      >
        <Dialog.Title
          as="h3"
          className="text-5xl font-bold sm:text-2xl text-black leading-8"
        >
          <div className="flex py-6 pl-8 pr-6 sm:items-center sm:px-2.5 sm:pt-4 sm:pb-0 plr-6">
            <div className="w-3/4">
              <h4 className="New-modal-heading">Share options</h4>
            </div>
            <div className="w-1/4 float-right">
              <div className="float-right">
                <div
                  className="flex items-center cursor-pointer"
                  onClick={closeModal}
                >
                  <span className="hidden sm:block">
                    <img
                      src="/Close.svg"
                      alt=""
                      // onClick={handleClick}
                      className="cursor-pointer hamburger-icon"
                    />
                  </span>
                  <span className="sm:hidden">
                    <CircleClose />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Dialog.Title>
        <hr />
        <div className="h-92-sm sm:flex sm:flex-col sm:justify-between">
          <div className="">
            <div className="flex p-6 sm:py-0 sm:px-6 sm:pb-6 sm:py-6">
              <div className="w-full share-input-container">
                <div className="mt-1 relative rounded-md">
                  <div>
                    <input
                      disabled={checkInvite}
                      ref={inviteRef}
                      onKeyUp={(event) => {
                        if (
                          event.key === ' ' ||
                          event.key === 'Enter' ||
                          event.key === '|'
                        ) {
                          handleKeyUp(event);
                        }

                        if (!typing) {
                          setIsTyping(true);
                        }
                        if (inviteRef.current?.value === '') {
                          setIsTyping(false);
                        }
                      }}
                      className="share-input overflow-hidden outline-0	"
                      placeholder="Email"
                      style={
                        roleSelectedDropDown.isEntireProject
                          ? { maxHeight: '40px', paddingRight: '32%' }
                          : { maxHeight: '40px' }
                      }
                    />
                  </div>
                  <div
                    ref={myref}
                    className="absolute inset-y-2-custom right-0 flex items-center email-input-dropdown"
                  >
                    <div
                      className="mr-5 share-option flex h-full py-0 pl-2 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md sm:mr-12 cursor-pointer"
                      tabIndex={0}
                    >
                      <div
                        className="flex items-center"
                        onClick={handleClickShareInputViewDropdown}
                      >
                        {/* {roleSelectedDropDown.role === 'VIEW'
                        ? 'can view'
                        : 'can edit'} */}
                        {!roleSelectedDropDown.isEntireProject
                          ? roleSelectedDropDown.role === 'VIEW'
                            ? 'Can view'
                            : roleSelectedDropDown.role === 'EDIT'
                              ? 'Can edit'
                              : roleSelectedDropDown.role === 'ADMIN'
                                ? 'Entire Project Access'
                                : 'Can View'
                          : roleSelectedDropDown.role === 'EDIT'
                            ? 'EDIT Project Access'
                            : 'View Project Access'}
                        {/* Dont Remove this  Usman */}
                        {/* {console.log("showMyProjects :", showMyProjects)}
                        {console.log("organization :", organization)}
                        {console.log("VIEW-CAPUTRE-ACCESS :", currentAccessType !== 'VIEW-CAPUTRE-ACCESS')} */}

                        {(currentAccessType !== 'VIEW-CAPUTRE-ACCESS' || (organization && !showMyProjects)) && (
                        // {(currentAccessType !== 'VIEW-CAPUTRE-ACCESS' || (!showMyProjects)) && (
                          <span
                            id={`ShareInputViewDropDown`}
                            className="cursor-pointer"
                          >
                            {!isCarrotUp ? (
                              <ArrowDown />
                            ) : (
                              <ArrowUpShare width={16} height={16} />
                            )}
                            {/* <ArrowDown /> */}
                          </span>
                        )}
                      </div>
                      <ShareInputViewDropdown
                        isShareInputViewDropdownShow={
                          isShareInputViewDropdownShow
                        }
                        selectedRole={setroleSelectedDropDown}
                        roleSelectedDropDown={roleSelectedDropDown.role}
                        isGroupShare={roleSelectedDropDown.isEntireProject}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {typing && (
              <div className="w-full pb-6 px-6">
                <textarea
                  ref={messageRef}
                  name=""
                  className="share-modal-txtarea"
                  id=""
                  placeholder="Message (optional)"
                ></textarea>
              </div>
            )}

            <div className="share-content scrollbox ">
              <div className="flex pb-6 ">
                <UserAvatar
                  uid={currentCaptureInfo?.creator.uid || ''}
                  width={32}
                  height={32}
                  customClass="user-avatar-custom"
                />
                <div className="flex-1 w-32">
                  <h4 className="share-modal-name py-1 ml-2">{`${currentCaptureInfo?.creator.displayName === undefined
                    ? currentUserInfo.uid === currentCaptureInfo?.creator.uid
                      // ? organization ? organization.name : currentUserInfo.displayName
                      ? currentUserInfo.displayName
                      : displayOwnerName
                    : displayOwnerName
                    }  ${currentUserInfo.uid === currentCaptureInfo?.creator.uid
                      ? '(you)'
                      : ''
                    }`}</h4>
                </div>
                <div className="ml-auto">
                  <span className="share-modal-options mr-3">Owner</span>
                </div>
              </div>
              <div>
                {currentCaptureShareInviteList.map((object, index) => {
                  return (
                    <ShareModalList
                      key={index}
                      object={object}
                      index={index}
                      setIsProcessing={setIsProcessing}
                      isProcessing={isProcessing}
                      listLenght={currentCaptureShareInviteList.length}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div>
            <NotifyMessage
              title={
                "As a free Plan Member, you can't restrict access to specific individual user for the project."
              }
              show={showDiv}
              wrapperClassName={'relative'}
              childClassName={
                'absolute p-3 -bottom-6 left-10 bg-black bg-opacity-70 text-white w-8/12 rounded-md'
              }
            />

            <div className="w-full flex items-center p-6 rounded-b border-t border-gray-200 dark:border-gray-600 sm:flex-wrap sm:pt-0 sm:items-start sm:bh-white">
              <div className="flex sm:flex-col items-center col-span-2 sm:w-2/5">
                <div
                  className="mt-3 flex-shrink-1 self-start mb-0 mr-4 w-1/12"
                  onClick={togglePublicAccess}
                >
                  <div
                    className={`md:w-9 md:h-4 w-9 h-4 flex items-center bg-gray-300 rounded-full mb-3 custom-radio-body mt-1
                  ${isProcessing ? 'cursor-wait' : cursornotAllowed}
                  ${publicAccess ? 'custom-green-radio' : 'custom-red-radio'}`}
                  >
                    <div
                      className={`bg-white md:w-5  md:h-5 h-5 w-5 rounded-full shadow-md transform
                    ${!publicAccess ? ' custom-radio' : toggleRadioClass}`}
                    ></div>
                  </div>
                </div>
                <div className=" mr-4 flex-1 self-start ml-4 sm:ml-0">
                  <h4 className="public-access">Public access</h4>
                  <p className="mt-1 public-access-desc ">
                    {publicAccess
                      ? 'Anyone with the link can see'
                      : 'Only invited users can see'}
                  </p>
                </div>
              </div>
              <div
                className={`${!publicAccess ? 'invisible' : 'none'
                  }  flex items-center sm:w-3/5 sm:flex-col`}
              >
                <div
                  className="mt-3 flex-shrink-1 self-end mb-0 mr-4 w-1/12 sm:self-start"
                  onClick={toggleAnonmousCommenting}
                >
                  <div
                    className={`md:w-9 md:h-4 w-9 h-4 flex items-center bg-gray-300 rounded-full mb-3  custom-radio-body mt-1 
                  ${isProcessing ? 'cursor-wait' : cursornotAllowed}
                  ${toggleAnonymousCommenting
                        ? 'custom-green-radio'
                        : 'custom-red-radio'
                      }`}
                  >
                    <div
                      className={
                        `bg-white md:w-5  md:h-5 h-5 w-5 rounded-full shadow-md transform ` +
                        (!toggleAnonymousCommenting
                          ? ' custom-radio'
                          : toggleRadioClass)
                      }
                    ></div>
                  </div>
                </div>
                <div className="mr-4 flex-1 self-start ml-4 sm:ml-0">
                  <h4 className="public-access">Allow anonymous commenting</h4>
                  <p className="mt-1 public-access-desc ">
                    Not logged in users can comment
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-between gap-4 items-center p-6 rounded-b border-t border-gray-200 dark:border-gray-600 sm:flex-wrap sm:items-start sm:bh-white">
              <div ref={copyLinkRef}>
                <button
                  className="share-copy-btn relative sm:flex sm:justify-center sm:items-center"
                  tabIndex={0}
                  onClick={handleClickCopyLinkShareDropdown}
                >
                  <span className="mx-3 sm:mx-2">
                    <CopyIcon />
                  </span>
                  <span className="mr-3 share-copy-btn-txt sm:mr-3">
                    Copy link
                  </span>
                  <span className="bottom-share mr-3 sm:mr-2">
                    {isCopyLinkShareDropdownShow ? (
                      <ArrowUpShare />
                    ) : (
                      <ArrowDownShare />
                    )}

                    <CopyLinkShareDropdown
                      isCopyLinkShareDropdownShow={isCopyLinkShareDropdownShow}
                    />
                  </span>
                </button>
              </div>
              <div className="flex items-center gap-12">
                <button
                  className="font-semibold sm:hidden hover:opacity-60"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <div className="sm:w-32">
                  <button
                    disabled={checkInvite}
                    type="button"
                    className={`${!typing ? 'cursor-not-allowed' : ''
                      } share-btn`}
                    onClick={sendInviteWithRoles}
                  >
                    {!checkInvite ? (
                      <>
                        <SendBigButton width={20} height={20} />
                        <span className={`send-txt ml-2.5`}>Send</span>
                      </>
                    ) : (
                      <div className="font-rubik w-full h-full flex flex-col justify-center items-center">
                        <div
                          style={{ borderTopColor: 'transparent' }}
                          className="w-4 h-4 border-1 border-black border-solid rounded-full animate-spin-fast"
                        ></div>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
};

export default ShareModal;
